import { Button, ButtonType, ToolTip, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import { exhaustiveSwitchCheck } from '@lego/b2b-unicorn-shared/utils';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React from 'react';

import { useCopyOrderItemsToCartContext } from './CopyOrderItemsToCart';

type CopyOrderItemsToCartButtonProps = {
  disabled: boolean;
  disabledTooltipMessage: string;
  buttonText: string;
  context: 'copyAllItems' | 'copyUnfulfilledItems';
};

export const CopyOrderItemsToCartButton: React.FC<CopyOrderItemsToCartButtonProps> = ({
  disabled,
  disabledTooltipMessage,
  buttonText,
  context,
}) => {
  const { triggerCopyOrderItemsToCart } = useCopyOrderItemsToCartContext();
  const { trackEvent } = useAnalytics();

  const triggerAnalyticsEventOnClick = (context: CopyOrderItemsToCartButtonProps['context']) => {
    let trackingEvent: Parameters<typeof trackEvent>[0];
    switch (context) {
      case 'copyAllItems':
        trackingEvent = {
          event: 'copyItemsToCartOrderDetails',
          name: 'User clicked on Copy Items to Cart from order details',
        };
        break;
      case 'copyUnfulfilledItems':
        trackingEvent = {
          event: 'copyUnfulfilledItemsToCartOrderDetails',
          name: 'User clicked on Copy Unfulfilled Items from order details',
        };
        break;
      default:
        exhaustiveSwitchCheck(context);
    }

    trackEvent(trackingEvent);
  };

  const handleOnClick = () => {
    triggerAnalyticsEventOnClick(context);
    triggerCopyOrderItemsToCart();
  };

  if (disabled) {
    return (
      <ToolTip
        message={disabledTooltipMessage}
        direction={ToolTipDirection.UpLeft}
      >
        <Button
          type={ButtonType.SECONDARY}
          size="small"
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </ToolTip>
    );
  }

  return (
    <Button
      type={ButtonType.SECONDARY}
      size="small"
      onClick={handleOnClick}
    >
      {buttonText}
    </Button>
  );
};
