import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { media } from '@lego/b2b-unicorn-ui-constants';

import { SubmenuItem } from './SubmenuItem';

const submenuStyle = css({
  position: 'static',
  display: 'flex',
  justifyContent: 'start',
  flexGrow: 1,
  borderTop: 'none',
  height: 56,
  backgroundColor: designToken.secondary.default,
  [media.large]: {
    justifyContent: 'center',
  },
});

export const ManageSubmenu = () => {
  const { orders_history, invoices, upcoming_deliveries } = useLabels();

  return (
    <section css={submenuStyle}>
      <SubmenuItem path="/manage">{orders_history}</SubmenuItem>
      <SubmenuItem path="/manage/invoices">{invoices}</SubmenuItem>
      <SubmenuItem path="/manage/upcoming-deliveries">{upcoming_deliveries}</SubmenuItem>
    </section>
  );
};
