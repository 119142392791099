import { EventAction, EventCategory } from '../';

export const downloadPdfEvents = {
  downloadOrderPdfManageOverview: {
    category: EventCategory.DownloadPdf,
    action: EventAction.DownloadOrderPdfManageOverview,
  },
  downloadOrderPdfOrderDetails: {
    category: EventCategory.DownloadPdf,
    action: EventAction.DownloadOrderPdfOrderDetails,
  },
  downloadInvoicePdfManageOverview: {
    category: EventCategory.DownloadPdf,
    action: EventAction.DownloadInvoicePdfManageOverview,
  },
  downloadInvoicePdfInvoiceDetails: {
    category: EventCategory.DownloadPdf,
    action: EventAction.DownloadInvoicePdfInvoiceDetails,
  },
};
