import React, { createContext, useCallback, useContext } from 'react';

import { EventMap, SiteId, trackerBaseUrl } from './constants';
import { pushInstruction, useMatomo } from './hooks/useMatomo';
import { checkUserConsent } from './utils/checkUserConsent';

export type ITrackEventParams = {
  event: keyof typeof EventMap;
  name: string;
  value?: number;
};

interface IAnalyticsContext {
  customTrackPageView: (title: string) => void;
  trackEvent: (params: ITrackEventParams) => void;
}

interface IAnalyticsProvider {
  children: React.ReactNode;
  cookieDomain: string;
  env: string;
  userConsent?: boolean;
}

const getSiteId = (env: string) => {
  return env === 'prod' ? SiteId.PROD : SiteId.DEV;
};

export const AnalyticsContext = createContext<IAnalyticsContext | undefined>(undefined);

const AnalyticsProvider = ({ children, cookieDomain, env }: IAnalyticsProvider): JSX.Element => {
  const { initializeMatomo, isScriptLoaded } = useMatomo();
  const userConsent = checkUserConsent();

  initializeMatomo({
    siteId: getSiteId(env),
    cookieDomain,
    trackerBaseUrl,
  });

  const contextValue: IAnalyticsContext = {
    customTrackPageView: useCallback(
      (title) => {
        // Need to set the URL before sending `trackPageView`
        // See: https://matomo.org/faq/how-to/how-do-i-set-a-custom-url-using-the-matomo-javascript-tracker/
        if (isScriptLoaded) {
          if (userConsent) {
            pushInstruction('requireCookieConsent');
          } else {
            pushInstruction('disableCookies');
          }
          pushInstruction('setCustomUrl', document.location.href);
          pushInstruction('setDocumentTitle', title);
          pushInstruction('trackPageView');
        }
      },
      [userConsent, isScriptLoaded]
    ),
    trackEvent: ({ event, name, value }) => {
      if (env !== 'prod') {
        console.info('AnalyticsEvent:', event, 'Name:', name, 'Value:', value); // eslint-disable-line no-console
      }
      const { category, action } = EventMap[event];
      pushInstruction('trackEvent', category, action, name, value);
    },
  };

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

const useAnalytics = () => {
  const contextValue = useContext(AnalyticsContext);
  if (contextValue === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return contextValue;
};

export { AnalyticsProvider, useAnalytics };
