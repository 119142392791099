import { EventAction, EventCategory } from '../';

export const copyItemsToCartEvents = {
  copyItemsToCartManageOverview: {
    category: EventCategory.CopyItemsToCart,
    action: EventAction.CopyItemsToCartManageOverview,
  },
  copyUnfulfilledItemsToCartManageOverview: {
    category: EventCategory.CopyItemsToCart,
    action: EventAction.CopyUnfulfilledItemsToCartManageOverview,
  },
  copyItemsToCartOrderDetails: {
    category: EventCategory.CopyItemsToCart,
    action: EventAction.CopyItemsToCartOrderDetails,
  },
  copyUnfulfilledItemsToCartOrderDetails: {
    category: EventCategory.CopyItemsToCart,
    action: EventAction.CopyUnfulfilledItemsToCartOrderDetails,
  },
};
