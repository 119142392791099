import { GoToTop } from '@lego/b2b-unicorn-ui-components';
import { TopbarImplementation } from '@lego/b2b-unicorn-ui-topbar';
import { Fragment, FunctionComponent, ReactNode } from 'react';

import { Banner } from '../../../shared/Components/Notifications';
import { ManageSubmenu } from './ManageSubmenu';

interface ManagePageLayoutProps {
  children: ReactNode;
}

const ManagePageLayout: FunctionComponent<ManagePageLayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <TopbarImplementation />
      <Banner />
      <ManageSubmenu />
      {children}
      <GoToTop />
    </Fragment>
  );
};

export default ManagePageLayout;
