import { tokens } from '@lego/core-colors';

export const font = {
  family: 'Cera Pro',
  lineHeight: 1.5,
  size: {
    tiny: 11,
    small: 14,
    small_mobile: 16,
    normal: 16,
    medium: 18,
    large: 28,
  },
  weight: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
};

export const mediaSizes = {
  small: 576,
  medium: 992,
  large: 1440,
  klik: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
  },
};

export const media = {
  small: `@media (min-width: ${mediaSizes.small}px)`,
  medium: `@media (min-width: ${mediaSizes.medium}px)`,
  large: `@media (min-width: ${mediaSizes.large}px)`,
  klik: {
    sm: `@media (min-width: ${mediaSizes.klik.sm})`,
    md: `@media (min-width: ${mediaSizes.klik.md})`,
    lg: `@media (min-width: ${mediaSizes.klik.lg})`,
    xl: `@media (min-width: ${mediaSizes.klik.xl})`,
  },
};

export const baseSpacing = 8;
export const maxDesktopWidth = 1440;

export const colors = {
  white: tokens.color.core.white,
  // deprecated, use designToken or tokens instead
  klik: {
    lightBlue10: '#F9FBFC',
    lightBlue30: '#F5F8FA',
    lightBlue50: '#E8F2FA',
    lightBlue100: '#CAE5FA',
    lightBlue200: '#A6D3F7',
    lightBlue300: '#79BCF2',
    lightBlue400: '#4599DE',
    lightBlue500: '#247BBF',
    lightBlue600: '#1766A6',
    lightBlue700: '#0F538A',
    lightBlue800: '#0C416B',
    lightBlue900: '#0A2C47',
    slate10: '#FCFCFC',
    slate30: '#F8F8F8',
    slate50: '#F1F1F1',
    slate100: '#E3E3E1',
    slate200: '#CFCFCC',
    slate300: '#B2B2B0',
    slate400: '#969694',
    slate500: '#767674',
    slate600: '#61615F',
    slate700: '#4D4D4B',
    slate800: '#3B3B39',
    slate900: '#292928',
  },
};

export const designToken = {
  primary: {
    default: tokens.color.core.azur[1200],
    active: tokens.color.core.azur[1100],
    hover: tokens.color.core.azur[1300],
  },
  secondary: {
    default: tokens.color.core.azur[700],
    active: tokens.color.core.azur[900],
    hover: tokens.color.core.azur[600],
  },
  interactive: {
    default: tokens.color.core.azur[900],
    active: tokens.color.core.azur[1000],
    hover: tokens.color.core.azur[800],
  },
  text: {
    default: tokens.color.core.offBlack,
    subdued: tokens.color.core.slate[1100],
    muted: tokens.color.core.slate[900],
    primary: tokens.color.core.blue[1100],
    secondary: `rgba(${tokens.color.core.white}, 0)`,
    emphasis: tokens.color.core.yellow[1200],
    informative: tokens.color.core.azur[1000],
    positive: tokens.color.core.green[1000],
    warning: tokens.color.core.orange[1000],
    negative: tokens.color.core.red[900],
    inverse: tokens.color.core.white,
    disabled: tokens.color.core.slate[400],
  },
  background: {
    blue: tokens.color.core.blue[50],
    offWhite: tokens.color.core.offWhite,
    white: tokens.color.neutral.white,
    gray: tokens.color.core.gray[50],
  },
  border: {
    information: tokens.color.core.azur[700],
    error: tokens.color.core.red[800],
    success: tokens.color.core.green[700],
    warning: tokens.color.core.orange[500],
    default: tokens.color.core.gray[200],
    subdued: tokens.color.core.gray[100],
    muted: tokens.color.core.gray[50],
    focus: tokens.color.core.azur[600],
    inverse: tokens.color.core.white,
  },
  error: {
    muted: tokens.color.core.red[50],
    subdued: tokens.color.core.red[100],
    default: tokens.color.core.red[800],
    emphasis: tokens.color.core.red[900],
    dark: tokens.color.core.red[1000],
  },
  information: {
    muted: tokens.color.core.azur[50],
    subdued: tokens.color.core.azur[100],
    default: tokens.color.core.azur[700],
    emphasis: tokens.color.core.azur[900],
    dark: tokens.color.core.azur[1100],
  },
  success: {
    muted: tokens.color.core.green[50],
    subdued: tokens.color.core.green[100],
    default: tokens.color.core.green[700],
    emphasis: tokens.color.core.green[900],
    dark: tokens.color.core.green[1100],
  },
  warning: {
    muted: tokens.color.core.orange[50],
    subdued: tokens.color.core.orange[100],
    default: tokens.color.core.yellow[500],
    emphasis: tokens.color.core.orange[900],
    dark: tokens.color.core.orange[1100],
  },
  transparent: {
    default: 'rgba(255, 255, 255, 0)',
    lightOverlay: tokens.color.transparent.white[50],
    darkOverlay: tokens.color.transparent.black[1300],
  },
  skeleton: {
    default: tokens.color.core.gray[100],
    primary: tokens.color.core.gray[400],
    secondary: tokens.color.core.gray[50],
  },
  white: tokens.color.core.white,
};
