import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { ProductImage } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';

const tableRowStyle = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: baseSpacing * 3,
  [media.small]: {
    display: 'table-row',
    position: 'initial',
  },
});

const itemIdCellStyle = css({
  position: 'absolute',
  left: baseSpacing,
  top: 13,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  [media.small]: {
    position: 'initial',
    width: 90,
  },
});

const imageWrapperStyle = css({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  '> img, svg': {
    height: 30,
    width: 'auto',
  },
});

const itemIDFontStyle = css({
  fontWeight: font.weight.bold,
  marginLeft: baseSpacing / 2,
});

const tableDataStyle = css({
  display: 'block',
  background: colors.white,

  [media.small]: {
    display: 'table-cell',
    verticalAlign: 'center',
    padding: baseSpacing,
    fontSize: font.size.small,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.klik.slate100,
  },
});

const nameCellStyle = css(tableDataStyle, {
  borderTopColor: colors.klik.lightBlue400,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderBottomColor: colors.klik.slate100,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  width: '100%',
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  paddingLeft: 120,
  marginBottom: baseSpacing / 2,
  [media.small]: {
    padding: baseSpacing,
    borderTop: 'none',
    width: 'auto',
  },
  [media.medium]: { width: 300 },
});

const quantityStyle = css(tableDataStyle, {
  paddingLeft: 120,
  [media.small]: {
    textAlign: 'right',
    paddingRight: baseSpacing * 3,
    paddingLeft: 0,
  },
});

const errorCellStyle = css(tableDataStyle, {
  color: designToken.error.default,
  paddingLeft: 120,
  [media.small]: {
    paddingLeft: 0,
    paddingTop: baseSpacing * 2,
    paddingBottom: baseSpacing * 2,
  },
});
export interface IRemovedItem {
  materialId?: number | null;
  itemNumber?: number | null;
  name?: string | null;
  reason: string;
  quantity?: number;
}

export const TableRowRemovedItem = ({
  materialId,
  itemNumber,
  reason,
  name,
  quantity,
}: IRemovedItem) => {
  const {
    not_in_catalog,
    max_quantity_exceeded,
    out_of_stock,
    not_available,
    retired,
    not_in_window,
    quantity_invalid,
  } = useLabels();

  const reasonMessage = (messageKey: string) => {
    return {
      NOT_IN_CATALOG: not_in_catalog, // in RemovedCartItemReason, FinalRowItem, validation: InvalidItem
      NOT_AVAILABLE: not_available, // in FinalRowItem,
      MARKED_NOT_AVAILABLE: not_available, // in RemovedCartItemReason, validation: InvalidItem
      OUT_OF_STOCK: out_of_stock, //in RemovedCartItemReason, FinalRowItem, validation: InvalidItem
      RETIRED: retired, // in validation: InvalidItem
      MAX_QUANTITY_EXCEEDED: max_quantity_exceeded, // in validation: InvalidItem
      NOT_IN_WINDOW: not_in_window, // in validation: InvalidItem
      REMOVED_BY_PRICING_SERVICE: '', //  in RemovedCartItem
      QUANTITY_INVALID: quantity_invalid, // in FinalRowItem
    }[messageKey];
  };

  const imageUrl = materialId && getImageUrl(materialId, ImageType.MAIN_BOX, 120);

  return (
    <tr css={tableRowStyle}>
      <td css={tableDataStyle}>
        <div css={itemIdCellStyle}>
          <div css={imageWrapperStyle}>
            {imageUrl && (
              <ProductImage
                src={imageUrl}
                alt="box image"
              />
            )}
          </div>
          <div css={itemIDFontStyle}>{itemNumber}</div>
        </div>
      </td>
      <td css={nameCellStyle}>{name}</td>
      {quantity !== undefined && <td css={quantityStyle}>{quantity}</td>}
      <td css={errorCellStyle}>{reasonMessage(reason)}</td>
    </tr>
  );
};
