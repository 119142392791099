import { css, keyframes } from '@emotion/react';

import { designToken } from '../theme';

const bg_position = keyframes`
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: 50% 0;
    }
`;

export const skeletonCss = css({
  width: '100%',
  height: '100%',
  boxShadow: 'none',
  backgroundClip: 'padding-box',
  cursor: 'default',
  color: 'transparent',
  pointerEvents: 'none',
  userSelect: 'none',
  flexShrink: '0',
  backgroundImage: `linear-gradient(90deg,${designToken.skeleton.secondary},${designToken.skeleton.default},${designToken.skeleton.default},${designToken.skeleton.secondary})`,
  backgroundSize: '400% 100%',
  animationName: bg_position,
  animationTimingFunction: 'ease-in-out',
  animationDuration: '1.5s',
  animationIterationCount: 'infinite',
});
