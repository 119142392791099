import { css } from '@emotion/react';
import React from 'react';

import { colors } from '../theme';

const spinnerStyle = (color = colors.klik.lightBlue600) =>
  css({
    color,
    width: '120px',
    height: '120px',
  });

type SpinnerOnlyProps = {
  color?: string;
};

type SpinnerProps = {
  expandToParentContainer?: boolean;
  color?: string;
};

const SpinnerOnly: React.FC<SpinnerOnlyProps> = ({ color }) => {
  return (
    <svg
      className="klik-ui-spinner"
      css={spinnerStyle(color)}
      role="graphics-symbol"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Loading</title>
      <style>
        {
          '@keyframes et091wy7biri2_tr__tr{0%{transform:translate(149.999996px,149.487495px) rotate(0deg)}50%{transform:translate(149.999996px,149.487495px) rotate(0deg);animation-timing-function:cubic-bezier(.42,0,.58,1)}to{transform:translate(149.999996px,149.487495px) rotate(180deg)}}@keyframes et091wy7biri5_to__to{0%{transform:translate(149.800003px,154.902515px);animation-timing-function:cubic-bezier(.42,0,.58,1)}16.666667%,to{transform:translate(150.199995px,216.972456px)}}@keyframes et091wy7biri6_to__to{0%{transform:translate(149.75px,81.987499px)}27.777778%{transform:translate(149.75px,81.987499px);animation-timing-function:cubic-bezier(.42,0,.58,1)}44.444444%,to{transform:translate(149.75px,143.987499px)}}@keyframes et091wy7biri9_to__to{0%{transform:translate(150px,149.5px)}5.555556%{transform:translate(150px,149.5px);animation-timing-function:cubic-bezier(.42,0,.58,1)}22.222222%,to{transform:translate(150px,220px)}}@keyframes et091wy7biri10_to__to{0%{transform:translate(202.699997px,115.5px)}16.666667%{transform:translate(202.699997px,115.5px);animation-timing-function:cubic-bezier(.42,0,.58,1)}33.333333%,to{transform:translate(201.699997px,183.600006px)}}@keyframes et091wy7biri11_to__to{0%{transform:translate(98.300003px,115.5px)}11.111111%{transform:translate(98.300003px,115.5px);animation-timing-function:cubic-bezier(.42,0,.58,1)}27.777778%,to{transform:translate(97.3px,183.6px)}}@keyframes et091wy7biri12_to__to{0%{transform:translate(150px,79px)}22.222222%{transform:translate(150px,79px);animation-timing-function:cubic-bezier(.42,0,.58,1)}38.888889%,to{transform:translate(150px,149.5px)}}.klik-ui-spinner path{fill:currentColor;stroke:none;strokeWidth:1}'
        }
      </style>
      <g
        aria-hidden="true"
        transform="translate(150 149.487)"
        style={{ animation: 'et091wy7biri2_tr__tr 1800ms linear infinite normal forwards' }}
      >
        <g
          id="et091wy7biri2"
          transform="translate(-150 -149.487)"
        >
          <path d="M150.2 258c-.9 0-1.8-.3-2.6-.8l-105.1-73c-2-1.4-2.5-4.2-1.1-6.3 1.4-2 4.2-2.5 6.3-1.1L150.2 248l102.5-71.2c2-1.4 4.8-.9 6.3 1.1 1.4 2 .9 4.8-1.1 6.3l-105.1 73c-.8.6-1.7.8-2.6.8Z"></path>
          <path d="M254.9 123c-.9 0-1.8-.3-2.6-.8L149.8 51 47.3 122.2c-2 1.4-4.8.9-6.3-1.1-1.4-2-.9-4.8 1.1-6.3l105.1-73c1.5-1.1 3.6-1.1 5.1 0l105.1 73c2 1.4 2.5 4.2 1.1 6.3-.8 1.2-2.2 1.9-3.6 1.9Z"></path>
          <g
            transform="translate(149.8 154.903)"
            style={{ animation: 'et091wy7biri5_to__to 1800ms linear infinite normal forwards' }}
          >
            <path
              d="M149.8 195.9c-.9 0-1.8-.3-2.6-.8l-105.1-73c-2-1.4-2.5-4.2-1.1-6.3 1.4-2 4.2-2.5 6.3-1.1L149.8 186l102.5-71.2c2-1.4 4.8-.9 6.3 1.1 1.4 2 .9 4.8-1.1 6.3l-105.1 73c-.8.5-1.7.7-2.6.7Z"
              id="et091wy7biri5"
              transform="translate(-149.8 -154.903)"
            ></path>
          </g>
          <g
            transform="translate(149.75 81.987)"
            style={{ animation: 'et091wy7biri6_to__to 1800ms linear infinite normal forwards' }}
          >
            <path
              d="M254.9 123c-.9 0-1.8-.3-2.6-.8L149.8 51 47.3 122.2c-2 1.4-4.8.9-6.3-1.1-1.4-2-.9-4.8 1.1-6.3l105.1-73c1.5-1.1 3.6-1.1 5.1 0l105.1 73c2 1.4 2.5 4.2 1.1 6.3-.8 1.2-2.2 1.9-3.6 1.9Z"
              transform="translate(-149.75 -81.987)"
            ></path>
          </g>
          <path d="M44.7 185.1c-2.5 0-4.5-2-4.5-4.5v-62.1c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v62.1c0 2.4-2 4.5-4.5 4.5Z"></path>
          <path d="M255.3 185.1c-2.5 0-4.5-2-4.5-4.5v-62.1c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v62.1c0 2.4-2 4.5-4.5 4.5Z"></path>
          <g
            transform="translate(150 149.5)"
            style={{ animation: 'et091wy7biri9_to__to 1800ms linear infinite normal forwards' }}
          >
            <path
              d="M150 168c-14.3 0-25.5-8.1-25.5-18.5S135.7 131 150 131s25.5 8.1 25.5 18.5S164.3 168 150 168Zm0-28c-9.7 0-16.5 5-16.5 9.5s6.8 9.5 16.5 9.5 16.5-5 16.5-9.5-6.8-9.5-16.5-9.5Z"
              transform="translate(-150 -149.5)"
            ></path>
          </g>
          <g
            transform="translate(202.7 115.5)"
            style={{ animation: 'et091wy7biri10_to__to 1800ms linear infinite normal forwards' }}
          >
            <path
              d="M201.7 134.1c-14.3 0-25.5-8.1-25.5-18.5s11.2-18.5 25.5-18.5 25.5 8.1 25.5 18.5-11.2 18.5-25.5 18.5Zm0-28c-9.7 0-16.5 5-16.5 9.5s6.8 9.5 16.5 9.5 16.5-5 16.5-9.5-6.7-9.5-16.5-9.5Z"
              transform="translate(-201.7 -115.6)"
            ></path>
          </g>
          <g
            transform="translate(98.3 115.5)"
            style={{ animation: 'et091wy7biri11_to__to 1800ms linear infinite normal forwards' }}
          >
            <path
              d="M97.3 134c-14.3 0-25.5-8.1-25.5-18.5S83 97 97.3 97s25.5 8.1 25.5 18.5c-.1 10.4-11.3 18.5-25.5 18.5Zm0-27.9c-9.7 0-16.5 5-16.5 9.5s6.8 9.5 16.5 9.5 16.5-5 16.5-9.5c-.1-4.5-6.8-9.5-16.5-9.5Z"
              transform="translate(-97.3 -115.5)"
            ></path>
          </g>
          <g
            transform="translate(150 79)"
            style={{ animation: 'et091wy7biri12_to__to 1800ms linear infinite normal forwards' }}
          >
            <path
              d="M150 97.5c-14.3 0-25.5-8.1-25.5-18.5s11.2-18.5 25.5-18.5 25.5 8.1 25.5 18.5-11.2 18.5-25.5 18.5Zm0-28c-9.7 0-16.5 5-16.5 9.5s6.8 9.5 16.5 9.5 16.5-5 16.5-9.5-6.8-9.5-16.5-9.5Z"
              transform="translate(-150 -79)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const backdropStyle = css({
  backgroundColor: `rgba(10, 10, 10, 0.1)`,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 11,
});

export const Spinner: React.FC<SpinnerProps> = ({ expandToParentContainer, color }) => {
  if (expandToParentContainer) {
    return (
      <div css={backdropStyle}>
        <SpinnerOnly color={color} />
      </div>
    );
  }

  return <SpinnerOnly color={color} />;
};
