export enum EventAction {
  'AddToCart' = 'AddToCart',
  'AddToCartLaunchWindow' = 'AddToCartLaunchWindow',
  'AddToCartLaunchWindowMultiple' = 'AddToCartLaunchWindowMultiple',
  'AddToCartPromotion' = 'AddToCartPromotion',
  'AddToCartReplenish' = 'AddToCartReplenish',
  'OpenLaunchWindow' = 'OpenLaunchWindow',
  'OpenReplenish' = 'OpenReplenish',
  'OpenPromotion' = 'OpenPromotion',
  'ReopenLaunchWindow' = 'ReopenLaunchWindow',
  'ReopenLaunchWindowMultiple' = 'ReopenLaunchWindowMultiple',
  'ReopenReplenish' = 'ReopenReplenish',
  'ReopenPromotion' = 'ReopenPromotion',
  'PasteLaunchWindow' = 'PasteLaunchWindow',
  'PasteLaunchWindowMultiple' = 'PasteLaunchWindowMultiple',
  'PasteReplenish' = 'PasteReplenish',
  'PastePromotion' = 'PastePromotion',
  'PasteOneColumnLaunchWindow' = 'PasteOneColumnLaunchWindow',
  'PasteOneColumnLaunchWindowMultiple' = 'PasteOneColumnLaunchWindowMultiple',
  'PasteOneColumnReplenish' = 'PasteOneColumnReplenish',
  'PasteOneColumnPromotion' = 'PasteOneColumnPromotion',
  'QuickAddToCart' = 'QuickAddToCart',
  'Swipe' = 'Swipe',
  'GotoProductDetails' = 'GotoProductDetails',
  'MainBrandLogoClick' = 'MainBrandLogoClick',
  'OpenContentHubOrderDetails' = 'OpenContentHubOrderDetails',
  'OpenContentHubProductDetails' = 'OpenContentHubProductDetails',
  'OpenContentHubManageOverview' = 'OpenContentHubManageOverview',
  'RightClick' = 'RightClick',
  'Select' = 'Select',
  'Deselect' = 'Deselect',
  'ShowMore' = 'ShowMore',
  'ShowLess' = 'ShowLess',
  'DownloadOrderPdfManageOverview' = 'DownloadOrderPdfManageOverview',
  'DownloadOrderPdfOrderDetails' = 'DownloadOrderPdfOrderDetails',
  'DownloadInvoicePdfManageOverview' = 'DownloadInvoicePdfManageOverview',
  'DownloadInvoicePdfInvoiceDetails' = 'DownloadInvoicePdfInvoiceDetails',
  'CopyItemsToCartManageOverview' = 'CopyItemsToCartManageOverview',
  'CopyUnfulfilledItemsToCartManageOverview' = 'CopyUnfulfilledItemsToCartManageOverview',
  'CopyItemsToCartOrderDetails' = 'CopyItemsToCartOrderDetails',
  'CopyUnfulfilledItemsToCartOrderDetails' = 'CopyUnfulfilledItemsToCartOrderDetails',
  'CreateClaimManageOverview' = 'CreateClaimManageOverview',
  'CreateClaimInvoiceDetails' = 'CreateClaimInvoiceDetails',
}
