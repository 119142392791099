import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { GlobalLoadingService } from '@lego/b2b-unicorn-bootstrap/services';
import { usePdfOrder } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { MenuItem } from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React, { useCallback, useRef } from 'react';

import { downloadFile } from '../../../../utils/downloadFile';

type DownloadPdfMenuItemProps = {
  orderNumber: number;
  onError?: (error: Error) => void;
};
export const DownloadPdfMenuItem: React.FC<DownloadPdfMenuItemProps> = ({
  orderNumber,
  onError,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const [pdfOrder] = usePdfOrder();
  const globalLoaderId = useRef<string>();
  const { button_download_pdf } = useLabels();
  const { trackEvent } = useAnalytics();

  const handleOnClick = useCallback(async () => {
    trackEvent({
      event: 'downloadOrderPdfManageOverview',
      name: 'User clicked on Download PDF from orders overview actions menu',
    });
    return pdfOrder({ variables: { orderNumber, customerId: selectedCustomer.id } });
  }, [orderNumber, pdfOrder, selectedCustomer.id]);

  const handleOnInFlight = useCallback(() => {
    if (globalLoaderId.current) {
      GlobalLoadingService.remove(globalLoaderId.current);
    }

    globalLoaderId.current = GlobalLoadingService.add('download-pdf-menu-item');
  }, []);

  const handleOnCompleted = useCallback(
    (result: Awaited<ReturnType<typeof pdfOrder>>) => {
      if (globalLoaderId.current) {
        GlobalLoadingService.remove(globalLoaderId.current);
        globalLoaderId.current = undefined;
      }

      if (result.error) {
        onError && onError(result.error);
      } else if (result.data) {
        downloadFile(result.data.getOrderPdf.fileDataAsBase64, result.data.getOrderPdf.fileName);
      }
    },
    [onError]
  );

  const handleOnErrored = useCallback(
    (e: Error) => {
      if (globalLoaderId.current) {
        GlobalLoadingService.remove(globalLoaderId.current);
        globalLoaderId.current = undefined;
      }

      onError && onError(e);
    },
    [onError]
  );

  return (
    <MenuItem
      onClickAsync={handleOnClick}
      onAsyncInFlight={handleOnInFlight}
      onAsyncCompleted={handleOnCompleted}
      onAsyncError={handleOnErrored}
    >
      <a
        href={'#'}
        onClick={(e) => e.preventDefault()}
      >
        {button_download_pdf}
      </a>
    </MenuItem>
  );
};
