import { css } from '@emotion/react';
import React, { ReactNode } from 'react';

import { baseSpacing, colors, designToken, mediaSizes } from '../theme';

const backgroundStyle = css({
  backgroundColor: designToken.secondary.default,
  color: colors.white,
  minHeight: 90,
  display: 'flex',
  alignItems: 'center',
});

const headerStyle = css({
  width: '100%',
  flexGrow: 1,
  maxWidth: mediaSizes.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: baseSpacing * 2,
  paddingRight: baseSpacing * 2,

  h1: {
    fontSize: '28px',
    margin: 0,
    fontWeight: 700,
  },
});

interface PageHeaderProps {
  children: ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  return (
    <div css={backgroundStyle}>
      <div css={headerStyle}>{props.children}</div>
    </div>
  );
};
