import { EventAction, EventCategory } from '../';

export const replenishCatalogEvents = {
  addToCartFromReplenishCatalog: {
    category: EventCategory.ReplenishCatalog,
    action: EventAction.AddToCart,
  },
  addToCartFromReplenishCatalogUsingQuickAdd: {
    category: EventCategory.ReplenishCatalog,
    action: EventAction.QuickAddToCart,
  },
  addToCartFromProductDetails: {
    category: EventCategory.ProductDetails,
    action: EventAction.AddToCart,
  },
};
