import { EventAction, EventCategory } from '../';

export const contentHubEvents = {
  contentHubLinkOrderDetails: {
    category: EventCategory.ContentHub,
    action: EventAction.OpenContentHubOrderDetails,
  },
  contentHubLinkProductDetails: {
    category: EventCategory.ContentHub,
    action: EventAction.OpenContentHubProductDetails,
  },
  // historical data in matomo can be seen under clickOnContentHubLink under Navigation category
  contentHubLinkManageOverview: {
    category: EventCategory.ContentHub,
    action: EventAction.OpenContentHubManageOverview,
  },
};
