import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, media } from '@lego/b2b-unicorn-ui-constants';
import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

const menuTextStyle = css({
  color: designToken.text.inverse,
  textDecoration: 'none',
  padding: baseSpacing,
  paddingLeft: baseSpacing * 3,
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontWeight: font.weight.medium,
  [media.small]: {
    padding: baseSpacing * 2,
  },
  '&:hover, :focus': {
    backgroundColor: designToken.secondary.hover,
    outline: 'none',
  },
});

const activeMenuTextStyle = css(menuTextStyle, {
  backgroundColor: designToken.secondary.active,
});

export interface ISubmenuItem {
  path: string;
  children: ReactNode;
}

const isActive = (path: string) => {
  const currentLocationPath = window.location.pathname;
  if (path === '/manage/invoices') {
    return currentLocationPath.includes('invoices');
  }
  if (path === '/manage/upcoming-deliveries') {
    return currentLocationPath.includes('upcoming-deliveries');
  }
  if (path === '/manage') {
    return (
      !currentLocationPath.includes('invoices') &&
      !currentLocationPath.includes('upcoming-deliveries')
    );
  }

  return true;
};

export const SubmenuItem: FunctionComponent<ISubmenuItem> = ({ children, path }) => {
  const isItemActive = isActive(path);

  return (
    <Link
      to={path}
      css={isItemActive ? activeMenuTextStyle : menuTextStyle}
      tabIndex={0}
    >
      {children}
    </Link>
  );
};
