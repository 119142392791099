import { EventAction, EventCategory } from './';
import {
  bulkQuickAddEvents,
  claimsEvents,
  contentHubEvents,
  copyItemsToCartEvents,
  downloadPdfEvents,
  generalNavigationEvents,
  recommendationsEvents,
  replenishCatalogEvents,
  themeTilesEvents,
} from './events';

export interface IEvent {
  category: keyof typeof EventCategory;
  action: keyof typeof EventAction;
}

// https://matomo.org/docs/event-tracking/
export const EventMap: Record<string, IEvent> = {
  ...bulkQuickAddEvents,
  ...claimsEvents,
  ...contentHubEvents,
  ...copyItemsToCartEvents,
  ...downloadPdfEvents,
  ...generalNavigationEvents,
  ...recommendationsEvents,
  ...replenishCatalogEvents,
  ...themeTilesEvents,
};
