import { EventAction, EventCategory } from '../';

export const bulkQuickAddEvents = {
  //// Replenishment
  addToCartFromBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartReplenish,
  },
  openBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.OpenReplenish,
  },
  reopenBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenReplenish,
  },
  pasteBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteReplenish,
  },
  pasteOneColumnBulkQuickAddReplenishment: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnReplenish,
  },
  //// Launch window
  addToCartFromBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartLaunchWindow,
  },
  openBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.OpenLaunchWindow,
  },
  reopenBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenLaunchWindow,
  },
  pasteBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteLaunchWindow,
  },
  pasteOneColumnBulkQuickAddLaunchWindow: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnLaunchWindow,
  },
  //// Launch windows overview
  reopenBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenLaunchWindowMultiple,
  },
  addToCartFromBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartLaunchWindowMultiple,
  },
  pasteBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteLaunchWindowMultiple,
  },
  pasteOneColumnBulkQuickAddLaunchWindowMultiple: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnLaunchWindowMultiple,
  },
  //// Promotion
  addToCartFromBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.AddToCartPromotion,
  },
  openBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.OpenPromotion,
  },
  reopenBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.ReopenPromotion,
  },
  pasteBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PastePromotion,
  },
  pasteOneColumnBulkQuickAddPromotion: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.PasteOneColumnPromotion,
  },
  rightClickBulkQuickAdd: {
    category: EventCategory.BulkQuickAdd,
    action: EventAction.RightClick,
  },
};
