import { css } from '@emotion/react';
import { baseSpacing, media } from '@lego/b2b-unicorn-ui-constants';

export const filterContainerStyle = css({
  paddingTop: baseSpacing * 5,
  fontSize: 14,
  position: 'relative',
  '> div': {
    paddingBottom: baseSpacing * 2,
    position: 'relative',
  },
  [media.medium]: {
    display: 'flex',
    justifyContent: 'space-between',
    '> div': {
      width: 340,
      flexShrink: 1,
      marginRight: baseSpacing * 2,
      paddingBottom: 0,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
});

export const tagContainerstyle = css({
  paddingTop: 30,
});
