import { EventAction, EventCategory } from '../';

export const claimsEvents = {
  createClaimManageOverview: {
    category: EventCategory.Claims,
    action: EventAction.CreateClaimManageOverview,
  },
  createClaimInvoiceDetails: {
    category: EventCategory.Claims,
    action: EventAction.CreateClaimInvoiceDetails,
  },
};
