import { gql } from '@apollo/client';

export const ORDER = gql`
  query order($customerId: Int!, $orderNumber: Int!) {
    order(customerId: $customerId, orderNumber: $orderNumber) {
      orderName
      orderNumber
      shipTo {
        id
        name
        city
      }
      orderDate
      requestedDeliveryDate
      calculatedOrderTotal {
        estimatedNetInvoicedPrice
        currency
      }
      orderStatus
      hasPdfOutput
      type
      shipmentInfo
      items {
        itemNumber
        materialId
        name
        theme
        confirmedPieces
        orderedPieces
        rejectedPieces
        price {
          currency
          estimatedNetInvoicedPrice
          netInvoicedPrice
        }
        rejected
        expectedDeliveryDate {
          pieces
          date
        }
      }
      fulfillmentStatus
    }
    products(customerId: $customerId) {
      products {
        materialId
      }
    }
  }
`;
