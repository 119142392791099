import { MenuItem, ToolTip, ToolTipDirection } from '@lego/b2b-unicorn-shared/ui';
import { exhaustiveSwitchCheck } from '@lego/b2b-unicorn-shared/utils';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import React from 'react';

import { useCopyOrderItemsToCartContext } from './index';

type CopyOrderItemsToCartMenuItemProps = {
  disabled: boolean;
  disabledTooltipMessage: string;
  buttonText: string;
  context: 'copyAllItems' | 'copyUnfulfilledItems';
};

export const CopyOrderItemsToCartMenuItem: React.FC<CopyOrderItemsToCartMenuItemProps> = ({
  disabled,
  disabledTooltipMessage,
  buttonText,
  context,
}) => {
  const { triggerCopyOrderItemsToCart } = useCopyOrderItemsToCartContext();
  const { trackEvent } = useAnalytics();

  const triggerAnalyticsEventOnClick = (context: CopyOrderItemsToCartMenuItemProps['context']) => {
    let trackingEvent: Parameters<typeof trackEvent>[0];
    switch (context) {
      case 'copyAllItems':
        trackingEvent = {
          event: 'copyItemsToCartManageOverview',
          name: 'User clicked on Copy Items to Cart from order overview',
        };
        break;
      case 'copyUnfulfilledItems':
        trackingEvent = {
          event: 'copyUnfulfilledItemsToCartManageOverview',
          name: 'User clicked on Copy Unfulfilled Items from order overview',
        };
        break;
      default:
        exhaustiveSwitchCheck(context);
    }

    trackEvent(trackingEvent);
  };

  const handleOnClick = () => {
    triggerAnalyticsEventOnClick(context);
    triggerCopyOrderItemsToCart();
  };

  return (
    <MenuItem
      disabled={disabled}
      onClick={handleOnClick}
    >
      {disabled && (
        <ToolTip
          message={disabledTooltipMessage}
          direction={ToolTipDirection.Left}
        >
          <span css={{ display: 'inline-block', width: 194 }}>{buttonText}</span>
        </ToolTip>
      )}
      {!disabled && (
        <a
          href={'#'}
          onClick={(e) => e.preventDefault()}
        >
          {buttonText}
        </a>
      )}
    </MenuItem>
  );
};
