import { EventAction, EventCategory } from '../';

export const recommendationsEvents = {
  swipeRecommendationsView: {
    category: EventCategory.Recommendations,
    action: EventAction.Swipe,
  },
  addToCartFromRecommendations: {
    category: EventCategory.Recommendations,
    action: EventAction.AddToCart,
  },
  gotoProductDetailsFromRecommendations: {
    category: EventCategory.Recommendations,
    action: EventAction.GotoProductDetails,
  },
  addToCartFromRecommendationsCart: {
    category: EventCategory.RecommendationsCart,
    action: EventAction.AddToCart,
  },
  swipeRecommendationsCart: {
    category: EventCategory.RecommendationsCart,
    action: EventAction.Swipe,
  },
  gotoProductDetailsFromRecommendationsCart: {
    category: EventCategory.RecommendationsCart,
    action: EventAction.GotoProductDetails,
  },
};
