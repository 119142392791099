import { EventAction, EventCategory } from '../';

export const themeTilesEvents = {
  themeTileSelecting: {
    category: EventCategory.ThemeTiles,
    action: EventAction.Select,
  },
  themeTileDeselecting: {
    category: EventCategory.ThemeTiles,
    action: EventAction.Deselect,
  },
  themeTilesShowMore: {
    category: EventCategory.ThemeTiles,
    action: EventAction.ShowMore,
  },
  themeTilesShowLess: {
    category: EventCategory.ThemeTiles,
    action: EventAction.ShowLess,
  },
};
