import type { ApolloError } from '@apollo/client';
import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useInvoice } from '@lego/b2b-unicorn-data-access-layer/react';
import { Price } from '@lego/b2b-unicorn-shared/components/Price';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { useOnPreferredLanguageChange } from '@lego/b2b-unicorn-shared/components/UserContext/hooks';
import { ExtractElementType } from '@lego/b2b-unicorn-shared/helpers';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import {
  Button,
  ButtonType,
  Heading,
  Icon,
  IconType,
  PageHeader,
  SubpageHeaderNav,
} from '@lego/b2b-unicorn-shared/ui';
import { useAnalytics } from '@lego/b2b-unicorn-ui-analyticscontext';
import { NotificationType, useNotifications } from '@lego/b2b-unicorn-ui-components';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';
import { useHasFeature } from '@lego/b2b-unicorn-ui-featuretogglecontext';
import React, { Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import DownloadPdfButton from '../../../components/DownloadPdfButton/DownloadPdfButton';
import ProductIdAndImage from '../../../components/ProductIdAndImage/ProductIdAndImage';
import { ProductName } from '../../../components/ProductName/ProductName';
import SkeletonTable from '../../../components/SkeletonLoaders/SkeletonTable';
import SortableTable from '../../../components/SortableTable/SortableTable';
import type { ColumnDefinitionType } from '../../../components/SortableTable/types';
import TruncatedText from '../../../components/TruncatedText/TruncatedText';
import { basicPageStyle } from '../../../styles/general';
import { tableStyle } from '../../../styles/table';
import { invoiceListStyles } from '../InvoiceList/styles';
import { orderDetailsPageStyle, orderDetailsTableContainerStyle } from '../OrderDetails/styles';
import { numericContentStyle, tableContainerStyle } from '../OrderList/styles';
import { buildInvoiceItemList } from './invoiceItemListParsing';
import InvoiceStatusSummary from './InvoiceStatusSummary';

const actionButtonsStyle = css({
  display: 'flex',
  gap: baseSpacing,
});

type InvoiceDetailsProps = {
  invoiceNumber: number;
  invoiceData?: ReturnType<typeof useInvoice>['data'];
  isLoading: boolean;
};

type InvoiceItemList = ExtractElementType<ReturnType<typeof buildInvoiceItemList>>;

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  invoiceNumber,
  invoiceData,
  isLoading,
}) => {
  useOnPreferredLanguageChange(['invoice']);
  const { id: customerId, locale } = useSelectedCustomer();
  const showClaims = useHasFeature('claims', false);

  const itemList = useMemo(() => {
    if (!invoiceData || !invoiceData.invoice) {
      return;
    }

    return buildInvoiceItemList(invoiceData.invoice.orders);
  }, [invoiceData]);

  const history = useHistory();
  const labelsContext = useLabels();
  const { snackbar, addSnackbar } = useNotifications();
  const { trackEvent } = useAnalytics();

  const handleCreateClaimClick = () => {
    trackEvent({
      event: 'createClaimInvoiceDetails',
      name: 'User clicked on Create Claim from invoice details page',
    });
    history.push(`/manage/invoice/${invoiceNumber}/create-claim`);
  };

  const handlePdfDownloadError = (error: ApolloError) => {
    logger.error(error);
    addSnackbar({
      type: NotificationType.WARNING,
      content: labelsContext.snackbar_download_file_error,
      showDismissButton: true,
      isStacked: !!snackbar,
    });
  };

  const colHeaders: ColumnDefinitionType<InvoiceItemList>[] = [
    {
      header: labelsContext.item_id,
      key: 'itemNumber',
      renderFunction: (row) => (
        <ProductIdAndImage
          materialId={row.materialId}
          itemNumber={row.itemNumber}
        />
      ),
      isSortable: true,
    },
    {
      header: labelsContext.name,
      key: 'name',
      renderFunction: (row) => (
        <ProductName
          name={row.name}
          materialId={
            invoiceData?.products?.products.find(
              (product) => product.materialId === row.materialId
            ) && row.materialId
          }
        />
      ),
    },
    {
      header: labelsContext.theme,
      key: 'theme',
      isSortable: true,
    },
    {
      header: labelsContext.pieces,
      key: 'quantity',
      renderFunction: (row) => <div css={numericContentStyle}>{row.quantity}</div>,
    },
    {
      header: labelsContext.order_number,
      key: 'orders',
      renderFunction: (row) => (
        <div>
          {row.orders.map((order) => (
            <div key={`orderno-${order.orderNumber}`}>{order.orderNumber}</div>
          ))}
        </div>
      ),
    },
    {
      header: labelsContext.order_name,
      key: 'orders',
      renderFunction: (row) => (
        <div>
          {row.orders.map((order) => (
            <TruncatedText key={`ordername-${order.orderName}`}>{order.orderName}</TruncatedText>
          ))}
        </div>
      ),
    },
    {
      header: labelsContext.your_price,
      key: 'price',
      renderFunction: (row) => (
        <div css={numericContentStyle}>
          <Price locale={locale}>{row.price}</Price>
        </div>
      ),
      isSortable: true,
      sortingFunction: (a, b, direction) =>
        (a.price.estimatedNetInvoicedPrice - b.price.estimatedNetInvoicedPrice) * direction,
    },
  ];

  return (
    <Fragment>
      <PageHeader>
        <SubpageHeaderNav
          goBackText={labelsContext.button_go_to_overview}
          onGoBack={() => history.push('/manage/invoices')}
        >
          <Heading
            level={1}
            designToken={'text.inverse'}
          >
            {' '}
            {labelsContext.invoice}: {invoiceNumber}{' '}
          </Heading>
          <div css={actionButtonsStyle}>
            {invoiceData?.invoice?.hasPdf && (
              <DownloadPdfButton
                customerId={customerId}
                invoiceNumber={invoiceNumber}
                onError={handlePdfDownloadError}
                type={ButtonType.PRIMARY_INVERTED}
                size="small"
                context={'invoiceDetails'}
              />
            )}
            {showClaims && (
              <Button
                type={ButtonType.PRIMARY_INVERTED}
                size={'small'}
                onClick={handleCreateClaimClick}
              >
                <Icon type={IconType.LIST_PLUS} />
                <div>{labelsContext.create_claim}</div>
              </Button>
            )}
          </div>
        </SubpageHeaderNav>
      </PageHeader>

      <div
        css={[
          basicPageStyle,
          tableStyle,
          tableContainerStyle,
          orderDetailsPageStyle,
          invoiceListStyles,
          orderDetailsTableContainerStyle,
        ]}
      >
        <>
          {invoiceData?.invoice !== null && (
            <>
              <InvoiceStatusSummary
                invoice={invoiceData?.invoice || undefined}
                locale={locale}
              />

              <h2>{labelsContext.invoice_details}</h2>

              {isLoading && <SkeletonTable rowsToRender={8} />}

              {itemList && (
                <SortableTable
                  columns={colHeaders}
                  rows={itemList}
                />
              )}
            </>
          )}
        </>
      </div>
    </Fragment>
  );
};

export default InvoiceDetails;
