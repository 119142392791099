export enum EventCategory {
  'Recommendations' = 'Recommendations',
  'RecommendationsCart' = 'RecommendationsCart',
  'ReplenishCatalog' = 'ReplenishCatalog',
  'ProductDetails' = 'ProductDetails',
  'Navigation' = 'Navigation',
  'BulkQuickAdd' = 'BulkQuickAdd',
  'ContentHub' = 'ContentHub',
  'ThemeTiles' = 'ThemeTiles',
  'DownloadPdf' = 'DownloadPdf',
  'CopyItemsToCart' = 'CopyItemsToCart',
  'Claims' = 'Claims',
}
