import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ValidationError } from 'yup';

import type { ExtractElementType } from '../../utils';
import { FileUploadService } from '../FileUpload';
import { Price } from '../Price';
import { ClaimFromValidationSchema, claimItemSchema, claimSchema } from './validation';

type PriceType = NonNullable<React.ComponentProps<typeof Price>['children']>;
type DocumentationFile = {
  status: number;
  fileSize: number;
  id: string;
};

type ClaimContextType<PromiseReturn = object> = {
  // Readonly state
  invoiceDetails: {
    invoiceNumber: number;
    deliveryNumber: number;
  };
  invoiceItems: {
    orderNumber: number;
    itemNumber?: number | null;
    materialId: number;
    invoiceLineNumber: number;
    name: string;
    pieces: number;
    piecePrice: PriceType;
    totalPrice: PriceType;
  }[];
  itemValidationSchema: typeof claimItemSchema;
  claimValidationSchema: typeof claimSchema;
  fileService: FileUploadService;

  // State getters
  claimItems: {
    materialId: number;
    orderNumber: number;
    invoiceLineNumber: number;
    name: string;
    claimType: string;
    pieces: number | null;
    piecePrice: PriceType;
    expectedPrice: PriceType | null;
    totalClaimPrice: PriceType | null;
    documentationFiles: DocumentationFile[];
    comment: string | null;
  }[];
  totalClaimAmount: PriceType;
  documentationFiles: DocumentationFile[];
  submitting: boolean;
  blockNavigation: boolean;
  hasError: boolean;
  comment?: string;
  validationErrors: ValidationError[];

  // State setters
  setClaimItems: React.Dispatch<
    React.SetStateAction<ClaimContextType<PromiseReturn>['claimItems']>
  >;
  setDocumentationFiles: React.Dispatch<
    React.SetStateAction<ClaimContextType<PromiseReturn>['documentationFiles']>
  >;
  setComment: React.Dispatch<React.SetStateAction<ClaimContextType<PromiseReturn>['comment']>>;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;

  // Action
  submitClaim: () => Promise<PromiseReturn>;
};

export type ClaimItem = ExtractElementType<ReturnType<typeof useClaim>['claimItems']>;
export type InvoiceOrderItem = ExtractElementType<ReturnType<typeof useClaim>['invoiceItems']>;
export const ClaimContext = React.createContext<ClaimContextType | undefined>(undefined);

export const useClaim = function <PromiseReturn = object>() {
  const context = useContext(
    ClaimContext as React.Context<ClaimContextType<PromiseReturn> | undefined>
  );
  if (context === undefined) {
    throw new Error('useCreateClaim must be used within a CreateClaimProvider');
  }
  return context;
};

type InvoiceData = {
  invoiceNumber: number;
  orders: {
    orderNumber: number;
    items: {
      itemNumber?: number | null;
      materialId: number;
      invoiceLineNumber: number;
      name: string;
      quantity: number;
      price: {
        __typename?: 'Price';
        currency: string;
        estimatedNetInvoicedPrice: number;
      };
    }[];
  }[];
};

type ClaimProviderProps<PromiseReturn> = {
  invoiceData: InvoiceData;
  customerId: number;
  onSubmitClaim: (
    claimValidationResult: ClaimFromValidationSchema | ValidationError[]
  ) => Promise<PromiseReturn>;
  children: React.ReactNode;
};

const sumClaimTotalAmount = (claimItems: ClaimContextType['claimItems']) => {
  const currency = claimItems[0]?.piecePrice?.currency || '';

  const sum = claimItems.reduce((acc, item) => {
    if (!item.totalClaimPrice) {
      return acc;
    }
    if (item.totalClaimPrice.__typename !== 'Price') {
      throw new Error('Expected totalClaimPrice to be of type Price');
    }
    return acc + item.totalClaimPrice.estimatedNetInvoicedPrice;
  }, 0);

  return {
    __typename: 'Price',
    estimatedNetInvoicedPrice: sum,
    currency,
  } as PriceType;
};

export const ClaimProvider = function <PromiseReturn extends object>({
  invoiceData,
  customerId,
  onSubmitClaim,
  children,
}: ClaimProviderProps<PromiseReturn>) {
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNumber: invoiceData.invoiceNumber ?? 0,
    deliveryNumber: 0,
  });
  const [invoiceItems, setInvoiceItems] = useState<ClaimContextType['invoiceItems']>([]);
  const [claimItems, setClaimItems] = useState<ClaimContextType['claimItems']>([]);
  const [documentationFiles, setDocumentationFiles] = useState<
    ClaimContextType['documentationFiles']
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [fileService] = useState(() =>
    FileUploadService.createInstance('claim-file-upload', {
      convert: {
        'image/*': {
          mimeType: 'image/jpeg',
          quality: 0.8,
          maxResolution: 2160,
        },
      },
      acceptedFileTypes: ['image/*', '.heic', '.heif', 'application/pdf'],
      maxParallelProcessing: 2,
      maxSize: 1024 * 1024 * 10,
    })
  );
  const [comment, setComment] = useState<ClaimContextType['comment']>(undefined);

  useEffect(() => {
    const fileServiceSub = fileService.run();

    return () => {
      fileServiceSub.unsubscribe();
    };
  }, [fileService]);

  useEffect(() => {
    const invoiceItems = invoiceData.orders.flatMap((order) =>
      order.items.map((item) => ({
        materialId: item.materialId,
        orderNumber: order.orderNumber,
        invoiceLineNumber: item.invoiceLineNumber,
        itemNumber: item.itemNumber,
        name: item.name,
        pieces: item.quantity,
        totalPrice: item.price,
        piecePrice: {
          __typename: 'Price',
          currency: item.price.currency,
          estimatedNetInvoicedPrice: item.price.estimatedNetInvoicedPrice / item.quantity,
        } as PriceType,
      }))
    );
    const sortedItems = invoiceItems.sort((a, b) => {
      const valueA = a.itemNumber || a.materialId;
      const valueB = b.itemNumber || b.materialId;
      return valueA - valueB;
    });
    setInvoiceItems(sortedItems);

    setInvoiceDetails({
      invoiceNumber: invoiceData.invoiceNumber,
      deliveryNumber: 0,
    });
  }, [customerId, invoiceData]);

  const validationResult: ValidationError[] | ClaimFromValidationSchema = useMemo(() => {
    try {
      const totalDocumentationFileSize = documentationFiles.reduce((acc, file) => {
        return acc + file.fileSize;
      }, 0);
      const totalClaimFileSize = claimItems.reduce((acc, item) => {
        return acc + item.documentationFiles.reduce((acc, file) => acc + file.fileSize, 0);
      }, 0);
      const totalClaimFilesCount = claimItems.reduce((acc, item) => {
        return acc + item.documentationFiles.length;
      }, documentationFiles.length); // We start count at the root of the claim

      const claimItemsForValidation = claimItems.map((item) => ({
        ...item,
        expectedPrice:
          item.expectedPrice && item.expectedPrice.__typename === 'Price'
            ? item.expectedPrice.estimatedNetInvoicedPrice
            : null,
      }));

      return claimSchema.validateSync(
        {
          invoiceNumber: invoiceDetails.invoiceNumber,
          claimItems: claimItemsForValidation,
          documentationFiles,
          comment,
          filesTotalFileSize: totalDocumentationFileSize + totalClaimFileSize,
          filesCount: totalClaimFilesCount,
        },
        { abortEarly: false }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        return error.inner;
      }
    }

    return [];
  }, [claimItems, documentationFiles, invoiceDetails.invoiceNumber, comment]);

  const totalClaimAmount = useMemo(() => sumClaimTotalAmount(claimItems), [claimItems]);
  const hasError =
    Array.isArray(validationResult) && validationResult.some((e) => e instanceof ValidationError);
  const blockNavigation = useMemo(
    () => claimItems.length > 0 && !submitting,
    [claimItems, submitting]
  );
  const validationErrors = useMemo(() => {
    if (!Array.isArray(validationResult)) {
      return [];
    }

    return validationResult.map((error) => {
      return error;
    });
  }, [validationResult]);

  const submitClaim = async () => {
    return onSubmitClaim(validationResult);
  };
  return (
    <ClaimContext.Provider
      value={{
        invoiceDetails,
        invoiceItems,
        claimItems,
        setClaimItems,
        totalClaimAmount,
        documentationFiles,
        setDocumentationFiles,
        hasError,
        itemValidationSchema: claimItemSchema,
        claimValidationSchema: claimSchema,
        submitClaim,
        submitting,
        setSubmitting,
        blockNavigation,
        fileService,
        comment,
        setComment,
        validationErrors,
      }}
    >
      {children}
    </ClaimContext.Provider>
  );
};
